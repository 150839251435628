<template>
  <AuthWrapper>
    <template #card>
      <b-alert variant="danger" show>
        <div class="alert-body font-small-2">
          <b-card-text>
            <feather-icon icon="AlertCircleIcon" />
            <h1>Hello {{ currentUser.first_name }},</h1>
            <small>Your account is pending verification. Usually approvals take between 24 - 48hrs. In case your account is not approved after 48hrs kindly contact support</small>
          </b-card-text>
        </div>
      </b-alert>

      <b-link class="text-right" @click="logout">
        <feather-icon icon="LogOutIcon" />
        Logout
      </b-link>
    </template>
  </AuthWrapper>
</template>

<script>
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";

import {
  BCol,
  BImg,
  BRow,
  BLink,
  BAlert,
  BButton,
  BCardText,
  BCardTitle,
} from 'bootstrap-vue'

export default {
  components: {
    AuthWrapper,
    BAlert,
    BRow,
    BCol,
    BLink,
    BImg,
    BButton,
    BCardText,
    BCardTitle,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
